<template>
  <div class="place-field">
    <div class="field">
      <div class="input-label">Display text</div>
      <input class="display-text-input input" :value="display_text" @change="editDisplayText" />

      <place-input
        placeholder="Not Selected"
        label="Related place"
        :id="identifier"
        :value="place"
        label-classes="input-label"
        multiselect-classes="bordered"
        @select="editRelatedPlace"
      ></place-input>
    </div>
  </div>
</template>

<script>
import PlaceInput from '@common/elements/inputs/PlaceInput';

export default {
  components: {PlaceInput},
  props: {
    label: String,
    location: Object, // {display_text, place_id, id}
    displayTextKey: {type: String, default: 'display_text'},
    identifier: String,
  },
  data() {
    return {
      display_text: this.location ? this.location[this.displayTextKey] : '',
      place:
        this.location && this.location.place_id
          ? {id: this.location.place_id, full_address_en: `Place ID ${this.location.place_id}`}
          : {},
    };
  },
  computed: {
    locationSelected() {
      return {[this.displayTextKey]: this.display_text, place_id: this.place.id || null};
    },
  },
  methods: {
    editDisplayText(event) {
      this.display_text = event.target.value;
      this.$emit('edit', this.locationSelected);
    },
    editRelatedPlace(place) {
      this.place = place;
      this.$emit('edit', this.locationSelected);
    },
  },
  name: 'PlaceField',
};
</script>

<style lang="scss" scoped>
.place-field {
  .label {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .display-text-input {
    width: 100%;
  }
}
</style>
