<template>
  <div class="relatives-json-field">
    <div v-for="relative in relatives" class="relative">
      <div class="field">
        <input v-model="relative.first_name" placeholder="First Name" class="input" />
      </div>
      <div class="field">
        <input v-model="relative.surname" placeholder="Surname" class="input" />
      </div>
      <div class="field">
        <input v-model="relative.relation" placeholder="Parent / Spouse / Child" class="input" />
      </div>
    </div>
    <a @click="onAdd">+ Add Relative</a>
  </div>
</template>

<script>
export default {
  props: {
    initRelatives: Array,
  },
  data() {
    return {
      relatives: this.initRelatives ? [...this.initRelatives] : [],
    };
  },
  methods: {
    onAdd() {
      this.relatives.push({first_name: '', surname: '', relation: ''});
    },
  },
  name: 'RelativesJsonField',
};
</script>

<style lang="scss" scoped>
.relatives-json-field {
  display: flex;
  flex-direction: column;
  .relative {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .field {
      width: 30%;
      flex-grow: 0;
      input {
        width: 100%;
      }
    }
  }
}
</style>
