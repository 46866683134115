<template>
  <person-full-edit-form ref="form" :form-initial-data="formInitialData" :allow-other-names="true">
    <place-field
      slot="birth-place-widget"
      slot-scope="{formData}"
      class="field place-field"
      label="Birth Place"
      :location="formData.birth_location"
      identifier="birth_location"
      @edit="editFormDataBirthLocation"
    ></place-field>
    <place-field
      slot="death-place-widget"
      slot-scope="{formData}"
      class="field place-field"
      label="Death Place"
      :location="formData.death_location"
      identifier="death_location"
      @edit="editFormDataDeathLocation"
    ></place-field>
    <place-field
      slot="residence-place-widget"
      slot-scope="{formData}"
      class="field place-field"
      label="Residence"
      :location="formData"
      identifier="residence_location"
      @edit="editFormDataResidenceLocation(formData, $event)"
    ></place-field>

    <div slot="extra-fields-end" class="extra-fields">
      <div class="field" v-if="showRelativesJsonField">
        <div class="bold label">Relatives Text</div>
        <relatives-json-field ref="relatives" :init-relatives="formInitialData.relatives_json"></relatives-json-field>
      </div>

      <div class="action-buttons">
        <mcr-button class="white bold" :disabled="formLoading" @click="$emit('cancel', $event)">Cancel</mcr-button>
        <mcr-button class="save bold" :loading="formLoading" @click="onSaveClick">Save</mcr-button>
      </div>
    </div>
  </person-full-edit-form>
</template>
<script>
import mcrButton from '@common/elements/buttons/mcrButton';

import RelativesJsonField from '@/components/common/forms/admin/RelativesJsonField';

import PlaceField from './PlaceField';

const PersonFullEditForm = () => import('@/components/common/tree/PersonFullEditForm');

export default {
  props: {
    formInitialData: Object,
    formLoading: Boolean,
    showRelativesJsonField: Boolean,
  },
  computed: {},
  methods: {
    editFormDataBirthLocation(data) {
      this.$refs.form.changeBirthLocation(data);
    },
    editFormDataDeathLocation(data) {
      this.$refs.form.changeDeathLocation(data);
    },
    editFormDataResidenceLocation(location, newData) {
      this.$refs.form.changeResidenceLocation(location, newData);
    },
    onSaveClick() {
      const data = this.$refs.form.getModifiedPersonForSave();
      const facts = this.$refs.form.getModifiedPersonFactsForSave();
      const newData = {...data};
      if (this.showRelativesJsonField) {
        newData.relatives_json = this.$refs.relatives.relatives.filter(r => r.first_name || r.surname || r.relation);
      }
      this.$emit('save', {person: newData, facts});
    },
  },
  components: {RelativesJsonField, PlaceField, PersonFullEditForm, mcrButton},
};
</script>
<style lang="scss" scoped>
.place-field {
  margin-bottom: 10px;
}
.extra-fields > .field {
  > .label {
    color: $neutral-600;
    margin-bottom: 5px;
  }
}
.action-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;

  .save {
    min-width: 150px;
  }
}
</style>
